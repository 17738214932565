import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { Badge } from '@components/badge'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'

const SECTION_ID = 'podwojna-radosc-oferta-swiateczna'

export const GastroCardFebruary2025Offer = (): React.ReactNode => {
  const { ref } = useScrollToOffer(SECTION_ID)
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.GastroCardFebruary2025)
  }

  return (
    <div className="container-lg" id={SECTION_ID} ref={ref}>
      <OfferSection
        onImageClick={handleShowDetails}
        badges={
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
            <Badge variant="red" text="Bestseller" className="mb-2 badge-pulsing col-12 col-sm-auto me-0 me-sm-3" />
            <Badge variant="gold" text="Oferta dostępna czasowo" className="mb-2 col-12 col-sm-auto" />
          </div>
        }
        title={
          <span className="font-header">
            Podwójna Radość <br /> Black Week <br />w lutym
          </span>
        }
        description={
          <span>
            Skorzystaj ze specjalnej promocji{' '}
            <span className="text-nowrap">
              na <strong className="text-nowrap">Voucher Podwójna Radość</strong>
            </span>{' '}
            <span className="text-nowrap">do wykorzystania </span>
            <strong>we wszystkich punktach gastronomicznych</strong> podczas pobytów{' '}
            <span className="text-nowrap">w latach 2026-2027 w Resortach </span> <br />
            <span className="text-nowrap">Holiday Park & Resort</span>.
          </span>
        }
        onOrderClick={handleShowDetails}
        offerKind="gastro-card-promo-february-2025"
        price={{
          pre: <div className="text-end font-size-sm fw-normal">Ulubiona</div>,
          after: <strong className="font-size-lg">promocja</strong>,
          shapePosition: 'bottom',
          className: 'font-sm pt-3',
        }}
      />
    </div>
  )
}

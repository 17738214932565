import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { formatPriceShort } from '@helpers/price-helper'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export const EndlessHolidayOffer = (): React.ReactNode => {
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.EndlessHoliday)
  }

  return (
    <div className="container-lg">
      <OfferSection
        title={<span className="font-header">Wakacje w nieskończoność 3.0</span>}
        description={
          <>
            <strong>Planuj urlop z wyprzedzeniem</strong> i ciesz się wyjątkowymi cenami za 6-dniowy pobyt{' '}
            <span className="text-nowrap">w Holiday Park & Resort.</span>
          </>
        }
        onOrderClick={handleShowDetails}
        onImageClick={handleShowDetails}
        offerKind="endless-holiday"
        rowClassName="flex-row-reverse"
        price={{
          pre: (
            <div>
              <div>Od</div>
              <strong className="font-header-lg">{formatPriceShort(299)}</strong>
            </div>
          ),
          after: (
            <div className="text-end">
              za 6 dni <br />w apartamencie
            </div>
          ),
          shapePosition: 'bottom',
          className: 'font-sm pt-3',
        }}
      />
    </div>
  )
}

import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { Badge } from '@components/badge'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'

export const CompanyOfferContact = (): React.ReactNode => {
  const appData = useAppSelector(selectAppData)

  const handleShowDetails = () => {
    window.open(`${appData.urls.other.main_site}/dla-firm/#formularz`, '_blank')
  }

  return (
    <div className="container-lg">
      <OfferSection
        badges={<Badge variant="gold" text="nowość" className="mb-2 me-3 badge-pulsing" />}
        title={
          <span className="font-header">
            Wyjątkowa <br /> oferta dla firm
          </span>
        }
        description={
          <>
            Sprezentuj rodzinne wczasy Pracownikom oraz Kontrahentom z wyjątkowym rabatem dla firm w formie voucherów{' '}
            <span className="text-nowrap">na 6-dniowy pobyt.</span>
          </>
        }
        onOrderClick={handleShowDetails}
        onImageClick={handleShowDetails}
        offerKind="company-offers-contact"
        price={{
          pre: <div className="text-end font-size-sm fw-normal">Oferta</div>,
          after: <strong className="font-size-lg">dla firm</strong>,
          shapePosition: 'bottom',
          className: 'font-sm pt-3',
        }}
      />
    </div>
  )
}

import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Route, Routes, useLocation } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { HomeView } from '@modules/index'
import { SubscriptionWithBenefitView } from '@modules/offers/subscription-with-benefit/details'
import { NotFoundView } from './ts/404'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { SubscriptionContractView } from '@modules/offers/subscription-contract/details'
import { CodesConversionView } from '@modules/offers/codes-conversion/details'
import { GoHolidayView } from '@modules/offers/go-holiday/details'
import { RulesView } from './ts/rules'
import { GastroCardDynamicView } from '@modules/offers/gastro-card/dynamic/details'
import { EndlessHolidayView } from '@modules/offers/endless-holiday/details'
import { useOfferAvailability } from '@modules/offers/use-offer-availability'
import { Layout } from '@layouts/layout'
import { GastroCardFebruary2025View } from '@modules/offers/gastro-card/february-2025/details'

export const AnimatedRouter = (): JSX.Element => {
  const location = useLocation()

  const { isAvailable } = useOfferAvailability()

  useDidUpdateEffect(() => {
    window.scroll({ top: 0, behavior: 'instant' })
  }, [location])

  return (
    <Layout>
      <TransitionGroup>
        <CSSTransition classNames="page" timeout={200}>
          <Routes>
            <Route path={NavigationPath.Homepage} element={<HomeView />} />
            <Route path={NavigationPath.SubscriptionWithBenefits} element={<SubscriptionWithBenefitView />} />
            <Route path={NavigationPath.EndlessHoliday} element={<EndlessHolidayView />} />
            {isAvailable('gastro_card_dynamic') && (
              <Route path={NavigationPath.GastroCardDynamic} element={<GastroCardDynamicView />} />
            )}

            <Route path={NavigationPath.GoHoliday} element={<GoHolidayView />} />
            <Route path={NavigationPath.SubscriptionContract} element={<SubscriptionContractView />} />
            <Route path={NavigationPath.CodeConversion} element={<CodesConversionView />} />

            {isAvailable('gastro_card_promo_february_2025') && (
              <Route path={NavigationPath.GastroCardFebruary2025} element={<GastroCardFebruary2025View />} />
            )}
            <Route path={NavigationPath.Rules} element={<RulesView />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  )
}

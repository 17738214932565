import { selectAppData } from '@store/slices/app-slice'
import { useAppSelector } from '@store/index'
import { ProductPackage } from '@models/app-state'

interface Response {
  endlessHolidayProductPackage: ProductPackage | undefined
}

export const useEndlessHolidayProductPackage = (): Response => {
  const { product_packages } = useAppSelector(selectAppData)

  return {
    endlessHolidayProductPackage: product_packages.find(productPackage => productPackage.kind === 'endless_holiday'),
  }
}

import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'

interface Props {
  offerName: string
  dates?: string
}

export const GastroCardBlackWeekCostsInfo = ({ offerName, dates = '01.01.2025 - 31.12.2026' }: Props): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>Minimalna kwota, za jaką możesz kupić voucher to 500,00 zł</p>
      <p>
        Z vouchera możesz korzystać wiele razy, przypisać go do wielu rezerwacji i przy każdej wykorzystać tylko jego
        część <span className="text-nowrap">aż do momentu</span> całkowitego wykorzystania.
      </p>
      <p>
        W ramach promocji <span className="fw-semi-bold">„{offerName}”</span> voucher można wykorzystać podczas pobytów
        w terminie <span className="text-nowrap">{dates}.</span>
      </p>
      <p>
        Voucher nie łączy się z voucherem Doładowanie Smaku i Strzał w dziesiątkę. Nie można go wykorzystać w
        rezerwacji, do której przypisany jest jakikolwiek voucher gastronomiczny. Po zużyciu środków z vouchera{' '}
        {offerName} można podpiąć kolejny dowolny voucher gastronomiczny.
      </p>

      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)

import * as React from 'react'
import { SubscriptionWithBenefitOffer } from '@modules/offers/subscription-with-benefit/subscription-with-benefit-offer'
import { SubscriptionContractOffer } from '@modules/offers/subscription-contract'
import { CodesConversionOffer } from '@modules/offers/codes-conversion'
import { BookingOffers } from '@modules/booking-offers'
import { SubscriptionsOffer } from '@modules/offers/subscriptions'
import { EndlessHolidayOffer } from '@modules/offers/endless-holiday'
import { useOfferAvailability } from '@modules/offers/use-offer-availability'
import { GastroCardFebruary2025Offer } from '@modules/offers/gastro-card/february-2025'
import { GastroCardChristmasOffer } from '@modules/offers/gastro-card/christmas'
import { CompanyOfferContact } from '@modules/offers/company-offers-contact'

export const OffersSection = (): React.ReactNode => {
  const { isAvailable } = useOfferAvailability()

  const dynamicRowClassOffers = [SubscriptionsOffer, CodesConversionOffer, SubscriptionContractOffer] as (({
    rowClassName,
  }) => JSX.Element)[]

  return (
    <>
      <SubscriptionWithBenefitOffer />
      {isAvailable('gastro_card_promo_february_2025') && <GastroCardFebruary2025Offer />}
      <EndlessHolidayOffer />
      <CompanyOfferContact />
      {isAvailable('gastro_card_christmas_2024') && <GastroCardChristmasOffer />}
      {dynamicRowClassOffers.map((Offer, index) => (
        <Offer key={index} rowClassName={index % 2 !== 0 ? '' : 'flex-row-reverse'} />
      ))}

      <BookingOffers />
    </>
  )
}

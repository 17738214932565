import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import {
  EndlessHolidayFormInputProduct,
  EndlessHolidayFormInputs,
  EndlessHolidayFormOption,
} from '@modules/offers/endless-holiday/details/index'
import { useEndlessHolidayTotalPrice } from '@modules/offers/endless-holiday/details/use-total-price'
import { useEndlessHolidayProductPackage } from '@modules/offers/endless-holiday/details/use-endless-holiday-product-package'

export const EndlessHolidayPayment = (): React.ReactNode => {
  const { getValues, control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const { endlessHolidayProductPackage } = useEndlessHolidayProductPackage()
  const { totalPrice } = useEndlessHolidayTotalPrice(products)

  const paymentAction = async (baseFields: BaseFormFields) => {
    const { product_package_endless_holiday_rules_3_0 } = getValues()

    const isWithClientDateChange = (product: EndlessHolidayFormInputProduct) =>
      product.options.some((option: EndlessHolidayFormOption) => option.kind === 'single_client_date')

    return await commonObjectPost<{ urls: { sale: string } }>(endlessHolidayProductPackage?.urls.sale || '', {
      products: products.map(product => ({
        product: product.product.id,
        amount: product.amount,
        related_product: product.related_product,
        single_client_date: isWithClientDateChange(product),
      })),
      product_package_endless_holiday_rules_3_0,
      ...baseFields,
    })
  }

  return (
    <OfferPaymentBar
      totalPrice={totalPrice}
      canSubmit={!!products.length}
      paymentAction={paymentAction}
      rulesKeywords={['product_package_endless_holiday_rules_3_0']}
    />
  )
}
